.dropdown-information-perfil-investigador .text-blue-propiedad-intelectual {
	font-weight: 600;
	font-size: var(--titleSize);
	color: #339ec9;
	line-height: 1.1;
}
.dropdown-information-perfil-investigador p.current-text-propiedad-intelectual {
	font-size: var(--text-base);
	color: #5f5e5e;
	max-width: 1600px;
	line-height: 1.4;
	margin: 0;
}

.text-blue-propiedad-intelectual-investigador {
	margin-bottom: 7px !important;
}
