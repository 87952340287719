footer {
	background-color: var(--primary);
	color: #fff;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 0;
	max-width: var(--custom-max-width);
	padding-bottom: 2%;
}

footer section {
	padding: 35px 15px 0px 14px;
	margin: auto;
	max-width: 1600px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

footer a {
	color: #fff;
	text-decoration: none;
}

.footer-logo {
	width: 250px;
}

footer .footer-content .footer-div-links .footer-title-div {
	font-weight: 600;
	font-size: var(--titleSize);
}

footer .footer-content .footer-div-links:nth-child(2) .footer-title-div {
	margin-bottom: 0px;
}

footer .footer-content .footer-div-links .footer-link-div {
	font-size: var(--titleSize);
	margin-bottom: 3%;
}

footer .footer-content .footer-text-logo {
	font-size: var(--titleSize);
}

footer .footer-content {
	max-width: 1600px;
	display: flex;
	place-content: space-between;
}

.footer-div-links {
	display: grid;
}

@media only screen and (max-width: 768px) {
	.footer-logo {
		width: 180px;
	}
	footer .footer-content .footer-div-links .footer-title-div {
		font-size: var(--titleSize);
	}
	footer .footer-content .footer-div-links .footer-link-div {
		font-size: var(--titleSize);
	}
	footer .footer-content .footer-text-logo {
		font-size: var(--titleSize);
	}
	.footer-content {
		flex-direction: column;
		align-items: center;
	}
	.footer-div-links {
		text-align: center;
	}
}

@media screen and (max-width: 900px) {
	footer {
		max-width: initial;
	}
}
