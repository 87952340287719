.container-hero-centro {
	background-image: var(--hub-hero-background-img);
	max-width: 1600px;
	margin: auto;
	height: 320px;
	color: #fff;
	display: grid;
	margin-top: 1%;
	align-items: end;
}
.container-hero-centro p {
	margin: 0;
}
.container-hero-centro .text-hero-centro {
	font-size: 20px;
	font-weight: 600;
}
.container-hero-centro a.link-hero-centro {
	font-size: 24px;
	color: #fff;
	text-decoration: none;
	margin-top: 15px !important;
}
.container-hero-centro .title-hero-centro {
	font-size: 35px;
	font-weight: 600;
	color: white;
}
.container-hero-items {
	padding: 0 0 25px 30px;
}
.items-container {
	margin-bottom: 25px;
	width: 51%;
}
@media screen and (max-width: 900px) {
	.items-container {
		width: 100%;
	}
	.container-hero-centro .title-hero-centro {
		font-size: 26px;
	}
}
