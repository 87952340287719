.container-servicio {
	text-align: left;
	margin-top: 5%;
	width: 100% !important;
}
.container-result-search .container-servicio {
	display: flex;
	margin-top: 5%;
	text-align: left;
}
.container-servicio .information-centro .link-centro {
	color: #fe894a;
	font-weight: 600;
	font-size: 24px;
}
.container-servicio .information-centro p.text-centro {
	font-size: 20px;
	color: #5f5e5e;
	margin: 3px 0px;
}
.container-servicio p.name_item_servicio {
	font-size: var(--text-base);
	color: #5f5e5e;
	display: inline-block;
	margin-bottom: 0;
	margin-top: 7px;
}
.container-servicio p.name_item_servicio.bold {
	font-weight: 600;
}
.container-servicio a.link_item_servicio {
	color: #0495c1;
	font-size: var(--text-base);
}
.container-servicio .nombre_servicio {
	color: var(--results-text);
	font-weight: 600;
	font-size: var(--titleSize);
	/* margin-top: 15%; */
	margin-bottom: 0;
}
.container-result-search .container-servicio {
	display: flex;
	margin-top: 5%;
	text-align: left;
	margin: 22px auto;
	width: max-content;
	margin-left: 0;
}
.container-servicio .information-centro .link-centro {
	color: #fe894a;
	font-weight: 600;
	font-size: 24px;
}
.container-servicio .information-centro p.text-centro {
	font-size: 20px;
	color: #5f5e5e;
	margin: 3px 0px;
}
.contacto_link_item_servicio {
	background-color: var(--primary);
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	padding: 2px 45px;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	margin-top: 3%;
	display: inline-block;
}
.link_item_servicio-no-link {
	font-size: var(--text-base);
	color: #5f5e5e;
	display: inline-block;
	margin-bottom: 0;
}
.information-centro {
	width: 100%;
}
.texto-centrado{
	text-align: justify;
}