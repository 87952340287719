.containerSearch {
	background-image: var(--home-background-img);
	background-repeat: no-repeat;
	height: 650px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.containerSearch .search {
	background-image: url("../../assets/icon_search.png");
	background-size: 35px;
	background-repeat: no-repeat;
	background-position: 97% 50%;
	max-width: 744px;
	max-height: 70px;
	padding-left: 10px;
	padding-right: 15px;
	width: 700px;
	height: 65px;
	font-size: var(--titleSize);
}

.containerSearch .search::-webkit-input-placeholder {
	text-align: center;
}
.subTextSearch {
	font-size: 18px;
	color: #5f5e5e;
}

.orangeSpacer {
	height: 38px;
	background-color: var(--primary);
	margin-top: 38px;
	margin-bottom: 38px;
}
.capsLogo {
	width: 238px;
}

@media screen and (max-width: 500px) {
	.containerSearch .search {
		font-size: 12px;
		background-image: none;
	}
}

@media screen and (max-width: 700px) {
	.containerSearch .search {
		font-size: 18px;
	}
}

@media screen and (max-width: 900px) {
	.formSearchBox {
		width: 90%;
	}

	.capsLogo {
		width: 140px;
	}
	.containerSearch .search {
		width: 100%;
		height: 60px;
	}
	.containerSearch {
		background-position: center;
	}

	.containerSearch {
		height: 450px;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

@media screen and (min-width: 1441px) {
	.containerSearch {
		background-size: cover;
	}
}
