.header-flex {
	display: flex;
    justify-content: space-between;
	max-width: var(--custom-max-width);
	margin: auto;
}
.logo-header {
	margin: auto 0;
	margin-top: 15px;
	margin-bottom: 15px;
	max-width: var(--custom-max-width);
	height: auto;
}

.logo-header-sdg{
	margin: auto 0;
	max-width: var(--custom-max-width);
	height: auto;
}

.logo-header-sdg{
	margin: auto 0;
	max-width: var(--custom-max-width);
	height: auto;
}

.logo-header-sdg .header-volver {
	border: none;
	display: inline-block;
	background-color: #dddddd;
	width: 95px;
	text-decoration: none;
	color: #5f5e5e;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	margin-top: 1%;
	font-weight: bold;
}


.logo-header .header-volver {
	border: none;
	display: inline-block;
	background-color: #dddddd;
	width: 95px;
	text-decoration: none;
	color: #5f5e5e;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	margin-top: 1%;
	font-weight: bold;
}

.logo-header-sdg .header-volver {
	border: none;
	display: inline-block;
	background-color: #dddddd;
	width: 95px;
	text-decoration: none;
	color: #5f5e5e;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	margin-top: 1%;
	font-weight: bold;
}


.gg-chevron-left {
	position: relative;
	border: 2px solid transparent;
	border-radius: 100px;
	margin-right: 16px;
}

.gg-chevron-left::after {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 10px;
	height: 10px;
	border-bottom: 2px solid;
	border-left: 2px solid;
	transform: rotate(45deg);
	left: 6px;
	top: 4px;
}

