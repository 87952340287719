button.back-arrow{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

svg.back-arrow{
    width: 50px;
    height: 50px;
}

path.back-arrow {
    fill: var(--technology-profile-color);
}

