.container-result-search .container-centro {
	display: flex;
	margin-top: 25px;
	text-align: left;
	
}
.container-centro .information-centro .link-centro {
	color: var(--results-text);
	font-weight: 600;
	font-size: var(--titleSize);
}
.container-centro .information-centro p.text-centro {
	font-size: var(--text-base);
	color: #5f5e5e;
	text-align: justify;
	margin: 3px 0px;
}
