.home-component {
	margin: auto;
	max-width: var(--custom-max-width);
}

.registration-container{
	max-width: 400px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 900px) {
	.home-component {
		max-width: 1600px;
	}
}

.label-pass{
	color: rgba(0, 0, 0, 0.6);
	font-size: small;
	margin-left: 12px;
}