.column {
	padding: 1%;
	margin: 1%;
}
.column .list-result {
	font-size: var(--titleSize);
	font-weight: 600;
	/* margin-top: 7.1%; */
	margin-bottom: 25px;
}
.column {
	display: block;
	align-items: baseline;
	padding: 0.5rem;
	gap: 1rem;
}
.dropdown.active > .link,
.link:hover {
	color: black;
}
.dropdown {
	position: relative;
}
.dropdown-menu {
	position: absolute;
	left: 0;
	top: calc(100% + 0.25rem);
	background-color: white;
	padding: 0.75rem;
	border-radius: 0.25rem;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.dropdown.active > .link {
	background-image: url("../../assets/chevron-top.png");
}
.dropdown-links {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}
p.number-per-filter {
	margin-left: auto;
}
.profile-investigador {
	display: flex;
}
a.profile-name-investigador {
	text-align: left;
	font-weight: 600;
	color: var(--primary);
	font-size: 24px;
	margin-top: 0;
	text-decoration: underline;
	margin-bottom: 0.5%;
	display: block;
}
p.profile-item-investigador {
	text-align: left;
	font-size: var(--text-base);
	color: #5f5e5e;
	margin: 1px 0px;
}
p.profile-resume-investigador {
	font-size: var(--text-base);
	text-align: justify;
	color: #5f5e5e;
}
img.profile-picture-investigador {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-right: 3%;
}
hr {
	margin-top: 3%;
	border: solid 0.1px #bebebe;
}

div.column div.profile-investigador:nth-of-type(2) {
	margin-top: 32px;
}

@media screen and (max-width: 425px) {
	img.profile-picture-investigador {
		width: 90px;
		height: 90px;
	}
}
