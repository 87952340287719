
.headerPrivacy{
	text-align: justify;
}
.privacy-components-container {
	margin: auto;
	max-width: var(--custom-max-width);
}

.privacy-components-container .title-politica{
	color: var(--results-text);
	font-weight: 600;
	font-size: var(--titlePolicySize);
}
.privacy-components-container .subtitle-politica {
	font-size: var(--titleSize);
	color: var(--profile-custom-color);
	font-weight: 600;
	margin-bottom: 7px;
}

.privacy-grid-information{
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 0;
	text-align: justify;
}


@media screen and (max-width: 900px) {
	.privacy-components-container {
		max-width: 1600px;
	}
}
