body header div img {
	width: 200px;
	height: fit-content;
}

.header-container {
	margin: auto;
	max-width: var(--custom-max-width);
}

.header-container {
	margin: auto;
	max-width: var(--custom-max-width);
}

.header-wrap {
	margin: auto;
	width: 80%;
}

.orange-text {
	color: var(--corfo-header-text);
	font-weight: bold;
}
.header-left .orange-text {
	margin-bottom: 0px;
}
.header-left .version-text {
	margin-top: 0px;
}
.header-right {
	float: right;
	display: grid;
	/* When the login will be added uncommend this line */
	/*display: none;*/
}
.header-right-login {
	float: right;
	display: grid;
	/* When the login will be added uncommend this line */
	/*display: none;*/
}
.header-right button {
	margin-bottom: 7px;
	background-color: white;
	border: 1px solid #bebebe;
	color: #bebebe;
	-webkit-border-radius: 80px;
	-moz-border-radius: 80px;
	border-radius: 80px;
	padding: 5px 12px 5px 12px;
}

.header-left {
	display: flex;
	justify-content: space-between;
}

.header-left > div {
	display: flex;
}

.header-information {
	font-size: var(--text-base);
	margin-left: 10px;
}

@media screen and (max-width: 500px) {
	.header-information {
		display: none;
	}
	.university-header-logo {
		display: flex;
		justify-content: center;
	}
	.header-left-child {
		display: block !important;
	}
	.header-left {
		flex-direction: column;
	}
	.hubtec-logo-container {
		justify-content: center;
		margin-bottom: 8px;
	}
	body header div img {
		width: 160px;
	}
}
@media screen and (max-width: 768px) {
	.header-hubtec-logo {
		width: 175px;
	}
}
@media screen and (max-width: 900px) {
	.header-container {
		max-width: 1600px;
	}

	.header-left {
		padding-left: 5px;
		padding-right: 5px;
	}
}
