.column {
	padding: 1%;
	margin: 1%;
}
.column .title-filter {
	font-size: var(--titleSize);
	text-align: center;
	font-weight: 600;
	/* margin-top: 20%; */
	margin-bottom: 40px;
}
.column {
	display: block;
	align-items: baseline;
	padding: 0.5rem;
	gap: 1rem;
}
.link {
	background: none;
	border: none;
	text-decoration: none;
	color: #000;
	font-family: inherit;
	font-size: var(--text-md);
	font-weight: 600;
	cursor: pointer;
	padding: 0;
	background-color: #dddddd;
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	border-radius: 70px;
	padding: 10px 50px;
	width: 100%;
	max-width: 350px;
	background-image: url("../../assets/chevron-down.png");
	background-repeat: no-repeat;
	background-position: 95%;
	margin-bottom: 35px;
	margin-top: 0px;
	margin-top: 7.1%;
}

.link::first-letter {
	text-transform: uppercase;
}

.sub-link {
	border: none;
	text-decoration: none;
	color: #5f5e5e;
	font-family: inherit;
	font-size: var(--text-base);
	cursor: pointer;
	width: 100%;
	max-width: 450px;
	text-align: left;
	padding: 10px 0px;
}
.dropdown.active > .link,
.link:hover {
	color: black;
}
.dropdown {
	position: relative;
}
.dropdown-menu {
	position: absolute;
	left: 0;
	top: calc(100% + 0.25rem);
	background-color: white;
	padding: 0.75rem;
	border-radius: 0.25rem;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
	max-width: 350px;
}
.dropdown.active > .link + .dropdown-menu {
	margin: auto;
	opacity: 1;
	transform: translateY(0);
	pointer-events: auto;
	position: initial;
}
.dropdown.active > .link {
	background-image: url("../../assets/chevron-top.png");
}
.information-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}
.dropdown-links {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}
p.number-per-filter {
	margin-left: auto;
}
.checkbox-container {
	display: flex;
	align-items: center;
}
.checkbox-container label {
	cursor: pointer;
	display: flex;
	align-items: center;
}
.checkbox-container input[type="checkbox"] {
	cursor: pointer;
	opacity: 0;
	position: absolute;
}
.checkbox-container label::before {
	content: "";
	padding: 0px 11px;
	width: 5px;
	height: 25px;
	margin-right: 0.5em;
	border: 0.05em solid #707070;
}
.checkbox-container input[type="checkbox"]:disabled + label,
.checkbox-container input[type="checkbox"]:disabled {
	color: #aaa;
	cursor: default;
}
.checkbox-container input[type="checkbox"]:checked + label::before {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	content: "x";
	color: var(--primary);
}
.checkbox-container input[type="checkbox"]:disabled + label::before {
	background-color: #ccc;
	border-color: #999;
}

@media only screen and (max-width: 900px) {
	.link {
		max-width: 440px;
	}
}
