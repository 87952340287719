/* @media screen and (max-width: 900px) {
	.container-dropdown-investigador .dropdown .title-overview {
		font-size: var(--titleSize);
	}
	.container-dropdown-investigador .dropdown .text-dropdown-generic {
		font-size: var(--text-base);
	}
}
 */

.title-overview--hub {
	margin-bottom: 7px;
	padding-right: 8%;
}

.dropdown-information-perfil-investigador .content-left-lineas-investigacion {
	padding-right: 8%;
}

.content-right-investigacion .title-lineas-investigacion {
	font-size: 18px;
	font-weight: 600;
	line-height: 1.3;
	width: 88%;
	text-align: left;
	margin: auto;
	margin-top: 8%;
	margin-bottom: 16%;
	color: #5f5e5e;
}