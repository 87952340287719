.dropdown-information-perfil-centro {
	left: 0;
	top: calc(100% + 0.25rem);
	background-color: white;
	padding: 0.75rem;
	border-radius: 0.25rem;
	transform: translateY(-10px);
	transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
	margin-bottom: 1.5%;
	text-align: justify;
}

.dropdown-information-perfil-centro .title-overview {
	margin-bottom: 8px;
}

.dropdown-information-perfil-centro a.text-big-overview {
	font-size: calc(var(--titleSize) + 2px);
	font-weight: 600;
	margin-top: 2.5%;
	color: var(--profile-custom-color);
	margin-bottom: 2.5%;
	text-decoration: underline;
	margin-top: 8px;
	display: inline-block;
	width: 100%;
	margin-bottom: 0px;
}
