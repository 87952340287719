.container-dropdown-investigador .body-lineas-de-investigacion-industrias {
	display: grid;
	grid-template-columns: 80% 20%;
	max-width: 1600px;
	margin-bottom: 3%;
}

.dropdown-information-perfil-investigador .content-left-lineas-investigacion {
	padding-right: 8%;
}

.container-dropdown-investigador p.item-industrias {
	font-size: var(--titleSize);
	color: #707070;
	margin-top: 3%;
	border: 1px solid #bebebe;
	padding: 3px 10px;
	width: fit-content;
}

.container-dropdown-investigador p.item-industrias-no-border {
	font-size: var(--titleSize);
	color: #707070;
	margin-top: 3%;
}


.container-dropdown-investigador .body-word-cloud {
	display: block;

}

.container-dropdown-investigador .body-word-cloud .word-cloud-container{
	display: flex;
	margin: 0 auto;
	width: 80%;
	height: 400px;
}




@media screen and (max-width: 900px) {
	.container-dropdown-investigador .dropdown .title-overview {
		font-size: var(--titleSize);
	}
	.container-dropdown-investigador .body-lineas-de-investigacion-industrias {
		grid-template-columns: 70% 30%;
	}
}
