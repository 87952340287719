.contact-form-modal-container {
	display: inline;
}
.contact-form-checkbox-form-input {
	color: var(--primary) !important;
}
.close-arrow {
	cursor: pointer;
	position: absolute;
	right: 30px;
}

.contacto-from__submit-button {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	cursor: pointer;
	background-color: var(--hub-resume-button);
	width: -moz-fit-content;
	width: 50%;
	text-decoration: none;
	border-radius: 70px;
	padding: 0.2% 2%;
	margin-top: 1.5%;
	border: none;
}

.contacto-from__submit-button.invalid {
	color: var(--hub-resume-button);
	background-color: #fff;
	border: 1px solid var(--hub-resume-button);
}

.title {
	color: #0495c1;
}

.modal-resume {
	text-align: justify;
}

.contact-form-title {
	text-align: center;
	color: #5f5e5e;
	margin: 0;
}

.form-control-container {
	display: flex;
	flex-direction: column;
	justify-items: center;
}

.form-control-container__label {
	margin-bottom: 10px;
	color: #6e6d6d;
}

.contacto-form-input {
	border: 1px solid #d3d3d3;
	height: 35px;
}

.modal-confirmation-button {
	background-color: var(--primary) !important;
}

.contacto-form-policies a{
	color: #3366CC;
}
@media screen and (max-width: 500px) {
	.title {
		font-size: var(--titleSize) !important;
	}

	.modal-resume {
		font-size: var(--text-base) !important;
	}
}
