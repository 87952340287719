.container-equipamento {
	text-align: left;
	margin-top: 40px;
}
.container-equipamento .information-equipamento .link-centro {
	color: #fe894a;
	font-weight: 600;
	font-size: 24px;
}
.container-equipamento .information-equipamento p.text-centro {
	font-size: 20px;
	color: #5f5e5e;
	margin: 3px 0px;
}
.container-equipamento p.name_item_equipamento {
	font-size: var(--text-base);
	color: #5f5e5e;
	display: inline-block;
	margin-bottom: 0;
}
.container-equipamento p.name_item_equipamento-descripcion {
	font-size: var(--text-base);
	color: #5f5e5e;
	display: inline;
	margin-bottom: 0;
}
.container-equipamento p.name_item_equipamento.bold {
	font-weight: 600;
}
.container-equipamento a.link_item_equipamento {
	color: #0495c1;
	font-size: var(--text-base);
}
.container-equipamento p.link_item_equipamento-doesnt-exist {
	display: inline;
	color: #5f5e5e;
	font-size: var(--text-base);
}
.nombre_equipamento {
	color: var(--results-text);
	font-weight: 600;
	font-size: var(--titleSize);
	margin-bottom: 0;
}
.contacto_link_item_equipamento {
	background-color: #fe894a;
	text-decoration: none;
	font-size: var(--text-base);
	font-weight: 600;
	color: #fff;
	padding: 1% 6% 0.9% 6%;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	margin-top: 3%;
	display: inline-block;
}
.contacto_link_item_equipamento-equipamento {
	background-color: var(--primary);
	text-decoration: none;
	font-size: var(--text-base);
	font-weight: 600;
	color: #fff;
	padding: 4px 23px;
	border-radius: 60px;
	margin-top: 3%;
	display: inline-block;
}
.item_equipamento-descripcion {
	text-align: justify;
}