.menu-item {
	text-decoration: none;
	border: 0.5px #bebebe solid;
	color: #000;
	padding: 0.5% 3%;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	font-weight: 600;
	font-size: var(--text-md);
	min-width: 500px;
	margin: 0 0.5%;
}

.menu-item.active {
	background-color: var(--results-tabs);
	color: #fff;
}
@media screen and (max-width: 500px) {
	.menu-category {
		display: flex;
		flex-direction: column;
	}
	.menu-item {
		min-width: initial;
		margin-bottom: 10px;
	}
}
@media screen and (min-width: 501px) and (max-width: 1300px) {
	.menu-category {
		overflow-y: scroll;
		padding: 10px 10px;
	}

	.menu-category::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}
