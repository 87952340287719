.container-result-search-technology .profile-investigador-tecnologia .link-centro-profile-investigador {
	color: #0495c1;
}
.container-result-search-technology .profile-investigador-tecnologia P.link-centro-profile-investigador {
	display: inline;
}
.container-result-search-technology img.profile-picture-investigador {
	width: 100px;
	height: 100px;
	margin-right: 2%;
}
.profile-name-investigador-tecnologia {
	text-align: left;
	font-weight: 600;
	color: var(--technology-profile-color);
	font-size: var(--titleSize);
	margin-top: 0;
	text-decoration: underline;
	margin-bottom: 0.5%;
	display: block;
}
.container-result-search-technology p.profile-item-investigador {
	text-align: left;
	font-size: var(--text-base);
	color: #5f5e5e;
	margin: 1px 0px;
}
.container-result-search-technology .profile-investigador-tecnologia {
	display: flex;
	margin-top: 2%;
	margin-bottom: 2%;
}

.no-underline {
	text-decoration: none !important;
}
