body hr {
	margin-top: 15px;
	border: solid 0.1px #bebebe;
	max-width: 1600px;
}
.profile-card-investigador .item-profile-investigador img.profile-picture-investigador {
	width: 200px;
	height: 200px;
}
section.profile-investigador {
	max-width: 1600px;
	display: grid;
	grid-template-columns: 40% 20% 40%;
	margin: auto;
	margin-top: 1.5%;
}
.profile-card-data-investigador {
	display: grid;
	grid-template-columns: 36% 64%;
	grid-gap: 10px;
	margin: auto;
}
.profile-investigador .profile-name-investigador {
	font-size: var(--titleSize);
	color: var(--profile-custom-color);
	font-weight: 600;
	margin: 0;
}
.profile-investigador p.profile-data-investigador {
	font-size: var(--text-base);
	color: #707070;
	margin: 0;
}
.profile-investigador a.link-centro-investigador {
	color: #0495c1;
}
.profile-edit-investigador {
	display: flex;
	margin: auto;
	align-items: center;
	place-content: space-between;
	background-color: var(--primary);
	color: #fff;
	font-size: 20px;
	padding: 0;
	margin: 0;
	margin-top: 5%;
}
.profile-edit-investigador p {
	margin: 0;
}
.profile-edit-investigador-button {
	color: #fff;
	font-weight: 600;
	margin-right: 5% !important;
	cursor: pointer;
	text-decoration: underline;
}
.profile-card-social-investigador .item-profile-investigador a {
	color: #0495c1;
	font-size: var(--text-base);
	text-decoration: none;
	top: 30%;
	position: absolute;
	margin-left: 2%;
}
.profile-card-social-investigador .item-profile-investigador-button {
	color: #0495c1;
	font-size: var(--text-base);
	text-decoration: none;
	top: 30%;
	position: absolute;
	margin-left: 2%;
}
.item-profile-investigador-contact-form-button {
	color: #0495c1;
	font-size: var(--text-base);
	text-decoration: none;
	margin-left: 2%;
}
.profile-card-social-investigador {
	border-left: 4px solid #bebebe;
	border-right: 4px solid #bebebe;
	margin-left: 5%;
	padding-left: 10%;
}
.item-profile-investigador {
	position: relative;
	padding-top: 3%;
}
.item-profile-investigador img {
	width: 50px;
}
.profile-card-data-number-investigador {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: auto;
	text-align: center;
	color: #707070;
	margin: auto 3%;
}
.profile-card-data-number-investigador .number-proyect-profile-investigador {
	font-size: 50px;
	font-weight: 600;
	margin: 0;
}
.profile-card-data-number-investigador .text-proyect-profile-investigador {
	font-size: var(--text-base);
}
.dropdown.active > .link,
.link:hover {
	color: #5f5e5e;
}
.dropdown {
	position: relative;
}
.dropdown-information-perfil-investigador {
	/* position: absolute; */
	left: 0;
	top: calc(100% + 0.25rem);
	background-color: white;
	padding: 0.75rem;
	border-radius: 0.25rem;
	/* opacity: 0; */
	/* pointer-events: none; */
	transform: translateY(-10px);
	transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.dropdown.active > .link + .dropdown-information-perfil-investigador {
	opacity: 1;
	transform: translateY(0);
	pointer-events: auto;
	position: initial;
	display: grid;
}
.container-profile-picture-investigador img {
	max-width: 100%;
}

.modal-confirmation-button {
	background-color: var(--primary) !important;
}
/* @media only screen and (max-width: 900px) {
  .profile-card-data-number-investigador .number-proyect-profile-investigador {
    font-size: 30px;
  }
  .profile-card-data-number-investigador {
    grid-template-columns: repeat(3, 1fr);
  }
   section.profile-investigador {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
} */

.researcher-components-container {
	margin: auto;
	max-width: var(--custom-max-width);
}

@media screen and (max-width: 500px) {
	.profile-edit-investigador p {
		font-size: var(--titleSize);
	}
}
@media screen and (max-width: 768px) {
	.container-profile-picture-investigador img {
		width: 145px;
	}
}
@media screen and (min-width: 900px) {
	.item-profile-investigador-contact-form-button {
		top: 30%;
		position: absolute;
	}
}
@media screen and (max-width: 900px) {
	.researcher-components-container {
		max-width: 1600px;
	}
	.profile-card-data-number-investigador .number-proyect-profile-investigador {
		font-size: 40px;
	}
	.profile-card-data-investigador {
		width: 80%;
	}

	section.profile-investigador {
		grid-template-rows: 1fr 142px 1fr;
	}
	.profile-card-investigador {
		grid-column: 1/4;
	}
	.profile-card-social-investigador {
		grid-column: 1/4;
		padding: 0;
		margin: 0;
		border: 0;
	}
	.profile-card-data-number-investigador {
		grid-column: 1/4;
		margin: auto;
		width: 100%;
	}
	.profile-card-social-investigador {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-content: center;
		justify-content: center;
		margin-top: 30px;
		gap: 10px;
	}
	.item-profile-investigador {
		position: initial;
		display: flex;
		flex-direction: column;
		justify-items: center;
		align-items: center;
	}
	.item-profile-investigador img {
		display: block;
		margin-bottom: 10px;
	}
	.profile-card-social-investigador .item-profile-investigador a {
		position: initial;
	}
}
@media screen and (max-width: 1400px) {
	.profile-card-data-investigador {
		grid-template-columns: auto 1fr;
	}
	.container-profile-card-data-investigador {
		margin: 0 2% 0 2%;
	}
}
