section.container-dropdown-investigador {
	max-width: 1600px;
	margin: auto;
	margin-top: 1%;
}
.dropdown button.link {
	width: 80%;
}
/* .container-dropdown-investigador .dropdown .link {
  background: none;
  text-decoration: none;
  color: #5f5e5e;
  font-family: inherit;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  padding: 0.7% 1.2%;
  margin-bottom: 1.5%;
  border: 1px solid #5f5e5e;
  text-align: left;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 3% max-content 10%;
} */
.container-dropdown-investigador .dropdown .link img {
	padding-top: 3%;
	margin-left: 7%;
}
.container-dropdown-investigador .dropdown .text-dropdown-generic {
	font-size: var(--text-base);
	color: #5f5e5e;
	line-height: 1.6;
	width: 100%;
	margin: 0;
}
.container-dropdown-investigador .dropdown .title-overview {
	font-size: var(--titleSize);
	color: var(--profile-custom-color);
	font-weight: 600;
	margin-bottom: 7px;
}

.container-dropdown-investigador .information-grid p {
	margin: 0.15%;
}
.container-dropdown-investigador .body-lineas-de-investigacion-industrias {
	display: grid;
	grid-template-columns: 80% 20%;
	max-width: 1600px;
	margin-bottom: 3%;
}
.body-lineas-de-investigacion-industrias .title-lineas-investigacion {
	font-size: var(--titleSize);
	font-weight: 600;
	color: var(--profile-custom-color) !important;
}

.body-word-cloud .title-word-cloud{
	font-size: var(--titleSize);
	font-weight: 600;
	color: var(--profile-custom-color) !important;
}
.container-dropdown-investigador p.item-industrias {
	font-size: var(--titleSize);
	color: #707070;
	margin-top: 3%;
	border: 1px solid #bebebe;
	padding: 3px 10px;
	width: fit-content;
}
.container-dropdown-investigador p.item-industrias-no-border {
	font-size: var(--titleSize);
	color: #707070;
	margin-top: 3%;
}
.dropdown-information-perfil-investigador .content-left-lineas-investigacion {
	padding-right: 8%;
}
.dropdown-information-perfil-investigador p.current-text-propiedad-intelectual {
	font-size: var(--text-base);
	color: #5f5e5e;
	margin-bottom: 0.5%;
	max-width: 1600px;
	line-height: 1.4;
}
.dropdown-information-perfil-investigador p.text-blue-publicaciones-cientificas {
	color: #339ec9;
	font-weight: 600;
	/* text-decoration: underline; */
	font-size: var(--titleSize);
	margin-bottom: 1%;
	margin-top: 10px;
	max-width: 1600px;
	margin-bottom: 7px;
}
.dropdown-information-perfil-investigador a.text-blue-publicaciones-cientificas,
a.text-blue-publicaciones-cientificas:hover {
	color: #339ec9;
	font-weight: 600;
	text-decoration: underline;
	font-size: var(--titleSize);
	margin-bottom: 7px;
	margin-top: 15px;
	max-width: 1600px;
	display: inline-block;
}

.dropdown-information-perfil-investigador p.current-text-publicaciones-cientificas {
	font-size: var(--text-base);
	line-height: 1.5;
	color: #5f5e5e;
}
.dropdown-information-perfil-investigador a.link-publicaciones-cientificas {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	background-color: var(--primary);
	width: fit-content;
	text-decoration: none;
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	border-radius: 70px;
	padding: 0.2% 2%;
	margin-top: 1.5%;
}
.dropdown-information-perfil-investigador .link-mas-publicaciones-cientificas {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	background-color: #5f5e5e;
	width: fit-content;
	text-decoration: none;
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	border-radius: 70px;
	padding: 0.2% 2%;
	margin-top: 1.5%;
	margin-bottom: 3%;
	cursor: pointer;
	border: 0px;
}
/* TODO check how to only use the CSS needed*/

.linkInvestigationLine {
	background: none;
	border: none;
	text-decoration: none;
	color: #5f5e5e;
	font-family: inherit;
	font-weight: 600;
	cursor: pointer;
	background-color: #fff;
	padding: 0.7% 1.2%;
	text-align: left;
	text-transform: uppercase;
	align-items: center;
}

.linkInvestigationLine img {
	padding-top: 3%;
	margin-left: 7%;
}

.flex-item-container {
	border: 1px solid #5f5e5e;
	padding-left: 12px;
	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	z-index: 1;
}

.chevrom-item {
	padding-top: 0.1rem;
}

@media only screen and (max-width: 900px) {
	.chevrom-item img {
		width: 25px;
	}
	.dropdown-information-perfil-investigador a.link-publicaciones-cientificas {
		font-size: var(--text-base);
	}
	section.container-dropdown-investigador {
		margin-top: 2%;
	}

	.container-dropdown-investigador .dropdown .title-overview {
		font-size: var(--titleSize);
	}

	.container-dropdown-investigador .dropdown .subtitle-politica {
		font-size: var(--titleSize);
	}
	.container-dropdown-investigador .dropdown .text-dropdown-generic {
		font-size: var(--text-base);
	}
}
