.containerSearchAdvanced {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 140px;
	text-align: center;
	background-color: var(--primary-light);
	vertical-align: middle;
	margin: auto;
	margin-top: 1%;
	max-width: var(--custom-max-width);
	align-items: center;
}
.containerSearchAdvanced p {
	font-size: 20px;
	color: #5f5e5e;
	width: 25%;
	margin: 15px auto 0 auto;
}
.containerSearchAdvanced .search {
	max-width: calc(100% - 40px);
	/* 40px is the with of the icon search */
	max-height: 80px;
	width: 744px;
	padding-left: 30px;
	height: 60px;
	font-size: var(--titleSize);
	margin-top: 2%;
	background-image: url("../../assets/icon_search.png");
	background-size: 35px;
	background-repeat: no-repeat;
	background-position: 95% 50%;
	border: none;
}
.sub-text-search {
	font-size: 18px;
	color: #5f5e5e;
}
.containerSearchAdvanced .search {
	margin: 0;
}

@media screen and (max-width: 900px) {
	.containerSearchAdvanced p {
		width: 100%;
	}
	.containerSearchAdvanced {
		max-width: initial;
	}
}
