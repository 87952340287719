:root {
	--primary: #fe894a;
	--primary-light: #ffc5a6;
	--corfo-header-text: #fe894a;
	--results-tabs: #fe894a;
	--results-text: #fe894a;
	--profile-custom-color: #fe894a;
	--hub-resume-button: #fe894a;
	--more-info-button-tecnology: #fe894a;
	--technology-profile-color: #fe894a;
	--hub-hero-background-img: url("/public/images/caps/CENTRO.png");
	--home-background-img: url("/public/images/caps/home.jpg");
	--titlePolicySize: 22px;
	--titleSize: 18px;
	--titleSizeTechnology: 22px;
	--subtitleSizeTechnology: 20px;
	--text-md: 16px;
	--text-base: 14px;
	/* This var refers to the right and left margin for the elements */
	--custom-max-width: 95%;
}

body {
	padding-top: 35px;
	margin: 0;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.amplify-pagination ol.amplify-flex {
	margin-top: 40px;
}

/* item */
.amplify-pagination .amplify-pagination__item-button[data-variation="link"] {
	color: var(--primary);
	background: transparent;
}

/* arrows */
.amplify-pagination li:last-child .amplify-pagination__item-button[data-variation="link"] {
	color: black;
}

.amplify-pagination li:first-child .amplify-pagination__item-button[data-variation="link"] {
	color: black;
}

/* active item */
.amplify-pagination .amplify-pagination__item-current,
.amplify-pagination__item-button[data-variation="link"] {
	color: black;
	background: transparent;
	font-size: 16px !important;
}

/* hovered */
.amplify-pagination .amplify-pagination__item-button[data-variation="link"]:hover {
	color: var(--amplify-components-pagination-button-color);
	background: transparent;
	border: none;
}

@media only screen and (max-width: 500px) {
	body {
		padding-top: 0;
	}
}

@media only screen and (max-width: 900px) {
	:root {
		--titleSize: 18px;
		--text-base: 14px;
	}
}
