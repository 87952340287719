#hub-components-container {
	margin: auto;
	max-width: var(--custom-max-width);
}

@media screen and (max-width: 900px) {
	#hub-components-container {
		max-width: 1600px;
	}
}
