.button {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	background-color: var(--hub-resume-button);
	width: -moz-fit-content;
	width: fit-content;
	text-decoration: none;
	border-radius: 70px;
	padding: 0.2% 2%;
	margin-top: 1.5%;
	border: none;
}

.title {
	color: #0495c1;
}

.modal-resume {
	text-align: justify;
}

@media screen and (max-width: 500px) {
	.abstract-modal-container {
		width: 90%;
	}
	.title {
		font-size: var(--titleSize) !important;
	}

	.modal-resume {
		font-size: var(--text-base) !important;
	}
}
