.container-technologia p.name_item_servicio {
	font-size: 16px;
	color: #5f5e5e;
	display: inline-block;
	margin-bottom: 0;
}
.container-technologia p.name_item_servicio.bold {
	font-weight: 600;
}
.container-technologia a.link_item_servicio {
	color: #0495c1;
	font-size: 16px;
}
.container-technologia .nombre_servicio {
	color: var(--results-text);
	font-weight: 600;
	font-size: var(--titleSize);
	/* margin-top: 15%; */
	margin-bottom: 0;
	display: block;
	text-decoration: none;
}
.contacto_link_item_servicio {
	background-color: var(--more-info-button-tecnology);
	text-decoration: none;
	font-size: var(--text-base);
	font-weight: 600;
	color: #fff;
	padding: 4px 23px;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	margin-top: 3%;
	display: inline-block;
}
.container-result-search .container-technologia {
	display: flex;
	text-align: left;
	margin: auto;
	margin-left: 0;
	margin-top: 32px;
}
