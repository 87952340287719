.container-profile-equipo {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	max-width: 1600px;
	grid-gap: 25px;
}

.profile-equipo {
	display: grid;
	grid-template-columns: 40% 60%;
}
.profile-equipo .container-text-profile-equipo {
	margin-right: 12%;
	margin-left: 1%;
}
.container-text-profile-equipo .name-profile-equipo {
	font-size: var(--titleSize);
	font-weight: 600;
	color: var(--profile-custom-color);
	text-decoration: underline;
}
.container-text-profile-equipo .sub-title-profile-equipo {
	font-size: 20px;
	font-weight: 600;
	color: #5f5e5e;
}
.container-text-profile-equipo .text-profile-equipo {
	font-size: var(--text-base);
	color: #5f5e5e;
}
.profile-equipo img.image-profile-equipo {
	width: 90%;
	max-width: 150px;
	height: 120px;
	object-fit: cover;
}
.name-profile-equipo-does-not-exist {
	font-size: var(--titleSize);
	font-weight: 600;
	color: var(--primary);
	text-decoration: none;
}

@media screen and (max-width: 900px) {
	.container-profile-equipo {
		grid-gap: 10px;
	}
	.profile-equipo img.image-profile-equipo {
		height: 60px;
	}
}
